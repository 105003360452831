import OfflineException from "@/exceptions/offlineException";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import ServerUnavailableException from "@/exceptions/serverUnavailableException";
import BadRequestException from "@/exceptions/badRequestException";
import InvalidBusinessOperationException from "@/exceptions/invalidBusinessOperationException";
import RequestCancelledException from "@/exceptions/requestCancelledException";
import UnauthorizedException from "@/exceptions/unauthorizedException";

export default class AlertHelper {
	static handleGeneralRequestErrors(error: Error) {
		switch (error.constructor) {
			case OfflineException:
				alertService.addError(AlertKeys.OFFLINE_REQUEST_ERROR);
				break;
			case ServerUnavailableException:
				alertService.addError(AlertKeys.SERVER_UNAVAILABLE_ERROR);
				break;
			case BadRequestException:
				alertService.addCustomError(error.message);
				break;
			case InvalidBusinessOperationException:
				alertService.addCustomError(error.message);
				break;
			case UnauthorizedException:
				alertService.addCustomError(error.message);
				break;
			case RequestCancelledException:
				break;
			default:
				alertService.addError(AlertKeys.REQUEST_UNKNOWN_ERROR);
				break;
		}
	}
}
