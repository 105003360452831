import axios, { AxiosRequestConfig } from "axios";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import UnauthorizedException from "@/exceptions/unauthorizedException";
import { i18n } from "@/plugins";
import { getHeaders } from "@/api/config/headers";
import { processApiPayload } from "@/utils/api";
import ServerUnavailableException from "@/exceptions/serverUnavailableException";
import OfflineException from "@/exceptions/offlineException";
import BadRequestException from "@/exceptions/badRequestException";
import InvalidBusinessOperationException from "@/exceptions/invalidBusinessOperationException";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import RequestCancelledException from "@/exceptions/requestCancelledException";

const classifyError = (error: any, { url }: { url: string }) => {
	if(axios.isCancel(error)) {
		return new RequestCancelledException();
	}

	if(error.response) {
		const data: any = error.response.data;

		if(error.response.status === 400)
			return new BadRequestException(data.title, data.detail);

		if(error.response.status === 401)
			return new UnauthorizedException(data.title, data.detail);

		if(error.response.status === 409)
			return new InvalidBusinessOperationException(data.title, data.detail);

		if(error.response.status === 404)
			return new HttpNotFoundException(error.response.config.url);

		if(error.response.status === 403)
			return new AccessForbiddenException(error.response.config.url);

		console.error(i18n.t("logs.error.requestError"), error.response.data);
		return error;
	} else {
		if(navigator.onLine) {
			return new ServerUnavailableException(url);
		} else {
			return new OfflineException(url);
		}
	}
};

class Client {
	async get<T>(url: string, options?: object): Promise<T> {
		try {
			const dateBeforeRequest = Date.now();
			console.log(i18n.t("logs.info.request", { url }));

			let { data } = await axios.get(`${url}`, {
				headers: getHeaders(),
				...options
			});

			console.log(i18n.t("logs.info.response", { url }), `time: ${Date.now() - dateBeforeRequest}ms`, data);

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}

	async put<T>(url: string, payload: any, config?: any): Promise<T> {
		try {
			console.log(i18n.t("logs.info.request", { url }), payload);

			let { data } = await axios.put(url, payload, {
				headers: getHeaders(),
				...config
			});

			console.log(i18n.t("logs.info.response", { url }), data);

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}

	async post<T>(url: string, payload: any, config?: AxiosRequestConfig): Promise<T> {
		if(payload)
			processApiPayload(payload);

		try {
			console.log(i18n.t("logs.info.request", { url }), payload);

			let { data } = await axios.post(url, payload, config);

			console.log(i18n.t("logs.info.response", { url }), data);

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}

	async delete(url: string): Promise<any> {
		try {
			console.log(i18n.t("logs.info.request", { url }));

			let { data } = await axios.delete(url, {
				headers: getHeaders()
			});

			console.log(i18n.t("logs.info.response", { url }), data);

			return data;
		} catch (error) {
			throw classifyError(error, { url });
		}
	}
}

const client = new Client();
export default client;
